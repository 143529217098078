import React, { useState } from 'react';
import axios from 'axios';

const API_BASE = process.env.REACT_APP_AMTT_SERVER_API_BASE_URL;

function submitForm(accessToken, data, setResponse) {

    console.log('**********Envoi de la requete au serveur**********');
    console.dir(accessToken);
    axios({
        url: `${API_BASE}/api/importation/file`,
        method: 'POST',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + accessToken.accessToken,
            //'Access-Control-Allow-Origin': '*',
            //'Access-Control-Allow-Methods': '*',
            //'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, Accept, Authorization'
        }
        }).then((response) => {
            setResponse(response.data);
            const respData = response.data;
            if(respData.success === 'ok'){
                alert('Le fichier a bien été importé.');
            }else{
                alert('Une erreur est survenue lors de l\'import du fichier. '+ respData.error);
            }
        }).catch((error) => {
            setResponse("error" + error);
    })
}

function UploadScreen(accessToken) {
 const [siteName, setSiteName] = useState('');
 const [file, setFile] = useState(null);
 const [fournisseur, setFournisseur] = useState('cgi');
 const atoken = accessToken;

 function uploadWithFormData(){
    const formData = new FormData();
    formData.append("siteName", siteName);
    formData.append("csvFile", file);
    formData.append("fournisseur", fournisseur);

    submitForm(atoken, formData, (msg) => console.log(msg));
 }


 return (
    <div className="App">
        <h2>Import des fichiers</h2>
        <form>
            <fieldset>
                <legend>Formulaire d'import</legend>
                <table>
                    <tbody>
                        <tr>
                            <td>Nom du site</td>
                            <td><input type="text" onChange={(e) => { setSiteName(e.target.value )}} /></td>
                        </tr>
                        <tr>
                            <td>Fournisseur</td>
                            <td>
                                <select onChange={(e) => { setFournisseur(e.target.value )}}>
                                    <option value="cgi">CGI</option>
                                    <option value="capitole">Capitole</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Fichier</td>
                            <td><input type="file" name="file" accept=".csv, text/csv" onChange={(e) => setFile(e.target.files[0])} /></td>
                        </tr>
                    </tbody>
                </table>
                <input type="button" value="Importer" onClick={uploadWithFormData} />
            </fieldset>
        </form>
    </div>
    );
}
 
export default UploadScreen;
