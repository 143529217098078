// Import FirebaseAuth and firebase.
import React from 'react';

import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";


import UploadScreen from './UploadScreen'

// Configure Firebase.
/*const config = { // Confid FB Samba
    apiKey: "AIzaSyDAY-vJkLZnt7S5_vnQxkiiHnPuZJaizPI",
    authDomain: "fir-auth-test-4d87b.firebaseapp.com",
    databaseURL: "https://fir-auth-test-4d87b.firebaseio.com",
    projectId: "fir-auth-test-4d87b",
    storageBucket: "fir-auth-test-4d87b.appspot.com",
    messagingSenderId: "448553378778",
    appId: "1:448553378778:web:71c6c6bcb8f0bf71f816e3",
    measurementId: "G-71LW5XERHJ"
  // ...
};*/
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, 
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMET_ID
  };
firebase.initializeApp(config);


class SignInScreenCustom extends React.Component {

    constructor(props) {
      super(props);
      
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handlePasswordChange = this.handlePasswordChange.bind(this);
      this.handleSignIn = this.handleSignIn.bind(this);
      this.handleLogout = this.handleLogout.bind(this);
      
      this.state = {
        email: '',
        password: '',
        error: '',
        signedIn: false,  // Local signed-in state.,
        accessToken: ''
      };
    }


    handleEmailChange(event) {
      this.setState({
        email: event.target.value,
      });
    }

    handlePasswordChange(event){
      this.setState({
        password: event.target.value,
      });
    }

    handleSignIn(){
      if (!this.state.email) {
        // alert('L\'email est obligatoire.');
        return this.setState({ error: 'L\'email est obligatoire.' });
      }
  
      if (!this.state.password) {
        // alert('Le mot de passe est obligatoire.');
        return this.setState({ error: 'Le mot de passe est obligatoire.' });
      }

      var athis = this;

      firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(function(firebaseUser) {
          console.dir(firebaseUser);
          //console.log(firebaseUser.user.xa);
          athis.setState({signedIn: true, accessToken: firebaseUser.user.xa});
      })
      .catch(function(error) {
        console.dir(error);
        return athis.setState({ error: 'Identifiants incorrects.' });
      });
    }

  handleLogout(){
    var aThis = this;
    firebase.auth().signOut().then(function() {
      console.log('Disconnected');
      aThis.setState({
        email: '',
        password: '',
        error: '',
        signedIn: false,  // Local signed-in state.,
        accessToken: ''
      });
    }).catch(function(error) {
      console.log(error);
      alert('Une erreur est survenue lors de la tentative de déconnexion');
    });
  }


  render() {
    if (!this.state.signedIn) {
      return (
        
        <div>
          <h1>AMTT Club Auto </h1>
          <p>Veuillez vous authentifier.</p>
          <form>
            <fieldset>
                <legend>Formulaire de connexion</legend>
                <p style={{color:'red'}}>
                  {this.state.error}
                </p>
                <table>
                    <tbody>
                        <tr>
                            <td>Email</td>
                            <td><input type="text" onChange={this.handleEmailChange} /></td>
                        </tr>
                        <tr>
                            <td>Mot de passe</td>
                            <td><input type="password" onChange={this.handlePasswordChange} /></td>
                        </tr>
                        
                    </tbody>
                </table>
                <input type="button" value="Se connecter" onClick={this.handleSignIn} />
            </fieldset>
        </form>
        <p>Environnement actuel : {process.env.REACT_APP_ACTUAL_ENV}</p>
        </div>
      );
    }
    return (
      <div>
        <h1>AMTT Club Auto</h1>
        <p>Bienvenue sur l'interface d'imports de fichiers!</p>
        <UploadScreen accessToken={this.state.accessToken} />
        <p>
            <input type="button" value="Se déconnecter" onClick={this.handleLogout} />
        </p>
      </div>
    );
  }
}

export default SignInScreenCustom;